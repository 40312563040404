import {
  AiOutlineDashboard,
  AiOutlineToTop,
  AiOutlineWallet,
} from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { GiTakeMyMoney } from "react-icons/gi";

import { MdOutlineSupportAgent } from "react-icons/md";
import { RiTeamLine, RiMoneyDollarCircleLine } from "react-icons/ri";
const walletMenuOption1 = [
  {
    id: "7854s8dfs5d",
    menu: "my wallet",
    route: "/dashboard/wallet/my-wallet",
    permission: ["user"],
  },

  {
    id: "8s8dfdepsex",
    menu: "Instant Deposit (NEW)",
    icon: <RiMoneyDollarCircleLine />,
    route: "/dashboard/wallet/instant-deposit",
    permission: ["user"],
  },
  // {
  //   id: "8s8dfdepser",
  //   menu: "Deposit Fund",
  //   icon: <RiMoneyDollarCircleLine />,
  //   route: "/dashboard/wallet/deposit-fund",
  //   permission: ["user"],
  // },
  {
    id: "fd7d8s48574",
    menu: "deposit history",
    route: "/dashboard/wallet/deposit-history",
    permission: ["user"],
  },
];
const walletMenuOption2 = [
  {
    id: "7854s8dfs5d",
    menu: "my wallet",
    route: "/dashboard/wallet/my-wallet",
    permission: ["user"],
  },

  {
    id: "8s8dfdepsex",
    menu: "Instant Deposit (NEW)",
    icon: <RiMoneyDollarCircleLine />,
    route: "/dashboard/wallet/instant-deposit",
    permission: ["user"],
  },
  // {
  //   id: "8s8dfdepser",
  //   menu: "Deposit Fund",
  //   icon: <RiMoneyDollarCircleLine />,
  //   route: "/dashboard/wallet/deposit-fund",
  //   permission: ["user"],
  // },
  {
    id: "fd7d8s48574",
    menu: "deposit history",
    route: "/dashboard/wallet/deposit-history",
    permission: ["user"],
  },
];
const getISTHoursAndMinutes = () => {
  const now = new Date();

  // Convert to IST (UTC +5:30)
  const istTime = new Date(now.getTime() + 5.5 * 60 * 60 * 1000);

  // Extract Hours and Minutes
  const istHours = istTime.getUTCHours().toString().padStart(2, "0");
  const istMinutes = istTime.getUTCMinutes().toString().padStart(2, "0");

  console.log(`🕒 Current IST Time: ${istHours}:${istMinutes}`);

  // return `${istHours}:${istMinutes}`;
  return { istHours, istMinutes };
};

const { istHours, istMinutes } = getISTHoursAndMinutes();
let walletMenu = [];

if (Number(istHours) >= 20) {
  walletMenu = walletMenuOption2;
} else {
  walletMenu = walletMenuOption1;
}
console.log("HIMANSHU", getISTHoursAndMinutes());
export const menus = [
  // ******************** user dashboard menu ************************//
  {
    id: "337fwedkf",
    menu: "dashboard",
    icon: <AiOutlineDashboard />,
    route: "/dashboard",
    permission: ["user"],
  },
  {
    id: "7dfser8srf",
    menu: "profile",
    icon: <FiUser />,
    permission: ["user"],
    dropdown: [
      {
        id: "8389r4ifd",
        menu: "my profile",
        route: "/dashboard/profile/my-profile",
        permission: ["user"],
      },
      {
        id: "f7d8e8sfrw",
        menu: "Edit profile",
        route: "/dashboard/profile/edit-profile",
        permission: ["user"],
      },
      {
        id: "sf7s8f5s8er",
        menu: "Update password",
        route: "/dashboard/profile/update-password",
        permission: ["user"],
      },
      // {
      //   id: "f7s5f8se8r",
      //   menu: "Update trx password",
      //   route: "/dashboard/profile/update-trxPassword",
      //   permission: ["user"],
      // },
      {
        id: "sfs4f8se8",
        menu: "Update email",
        route: "/dashboard/profile/update-email",
        permission: ["user"],
      },
      {
        id: "d7f8wer8s",
        menu: "Edit wallet",
        route: "/dashboard/profile/edit-wallet",
        permission: ["user"],
      },
      {
        id: "d7f8we3ap",
        menu: "Add Pin",
        route: "/dashboard/profile/add-pin",
        permission: ["user"],
      },
    ],
  },
  {
    id: "f7df8sfd7f",
    menu: "wallet",
    icon: <AiOutlineWallet />,
    permission: ["user"],
    dropdown: walletMenu,
  },
  {
    id: "df79er8suf",
    menu: "my team",
    icon: <RiTeamLine />,
    permission: ["user"],
    dropdown: [
      {
        id: "r3er8re8sdf7dn",
        menu: "direct team",
        route: "/dashboard/team/direct-team",
        permission: ["user"],
      },
      {
        id: "fs7erw55s",
        menu: "level team",
        route: "/dashboard/team/level-team",
        permission: ["user"],
      },
      {
        id: "fs7erw55ssts",
        menu: "team business",
        route: "/dashboard/team/team-business",
        permission: ["user"],
      },
    ],
  },
  {
    id: "m5vko5f5sfo3s5f",
    menu: "Top up",
    icon: <AiOutlineToTop />,
    permission: ["user"],
    dropdown: [
      {
        id: "fnd5fhs5or5f",
        menu: "topup account",
        route: "/dashboard/topup/topup-account",
        permission: ["user"],
      },
      {
        id: "vnzs5dajio5df8",
        menu: "topup history",
        route: "/dashboard/topup/topup-history",
        permission: ["user"],
      },
    ],
  },
  {
    id: "fsfsfusf8se4r",
    menu: "my earning",
    icon: <GiTakeMyMoney />,
    permission: ["user"],
    dropdown: [
      {
        id: "e7rfs5fs25gsenbv",
        menu: "Level Income",
        route: "/dashboard/earnings/level-income",
        permission: ["user"],
      },
      {
        id: "e7rfs5fs25gsenbv",
        menu: "Daily Income",
        route: "/dashboard/earnings/daily-income",
        permission: ["user"],
      },
      {
        id: "fs55s8fs8fs",
        menu: "Roi Income",
        route: "/dashboard/earnings/roi-income",
        permission: ["user"],
      },
      {
        id: "fs55s8fs8fssstrs",
        menu: "Rank Income",
        route: "/dashboard/earnings/rank-income",
        permission: ["user"],
      },
      // {
      //   id: "7jsuuwer7f8sf",
      //   menu: "Bonus Income",
      //   route: "/dashboard/earnings/bonus-income",
      //   permission: ["user"],
      // },
      // {
      //   id: "fdf8s8f8s8fs",
      //   menu: "Reward",
      //   route: "/dashboard/earnings/reward",
      //   permission: ["user"],
      // },
    ],
  },
  {
    id: "bv2sre2ws7sf",
    menu: "withdraw",
    icon: <BiTransfer />,
    permission: ["user"],
    dropdown: [
      {
        id: "vv4mk4ufi4sf",
        menu: "withdraw funds",
        route: "/dashboard/withdraw/withdraw-fund",
        permission: ["user"],
      },
      {
        id: "vn2sff47s7f7d",
        menu: "withdraw history",
        route: "/dashboard/withdraw/withdraw-history",
        permission: ["user"],
      },
    ],
  },
  {
    id: "vn3es6uru3sf7",
    menu: "support",
    icon: <MdOutlineSupportAgent />,
    permission: ["user"],
    dropdown: [
      {
        id: "sfs4f4s8e8r",
        menu: "updates",
        route: "/dashboard/support/updates",
        permission: ["user"],
      },
      {
        id: "vmj4dfs4f8sf",
        menu: "support ticket",
        route: "/dashboard/support/support-ticket",
        permission: ["user"],
      },
      {
        id: "vmj4de4ru4sf7s7",
        menu: "ticket history",
        route: "/dashboard/support/ticket-history",
        permission: ["user"],
      },
      {
        id: "f7s8f8fd",
        menu: "Contact Us",
        route: "/dashboard/support/contact-us",
        permission: ["user"],
      },
    ],
  },
];

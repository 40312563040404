import React from "react";
import Page404 from "../containers/Page404NotFound/Page404";
const UserDashboard = React.lazy(() =>
  import("../containers/UserPages/HomePage/index")
);
// user profile
const MyProfile = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.MyProfile")
);
const UpDateProfile = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.UpdateProfile")
);
const UpDatePassword = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.UpdatePassword")
);
const UpDateEmail = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.UpdateEmail")
);
const EditWallet = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.UpdateWallet")
);
const addPin = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.AddPin")
);
// wallet section
const wallet = React.lazy(() =>
  import("../containers/UserPages/Wallet/Wallet")
);
const DepositFund = React.lazy(() =>
  import("../containers/UserPages/Wallet/DepositFund")
);
const InstantDeposit = React.lazy(() =>
  import("../containers/UserPages/Wallet/InstantDeposit")
);
const DepositHistory = React.lazy(() =>
  import("../containers/UserPages/Wallet/DepositFundHistory")
);
const FundReceivingHistory = React.lazy(() =>
  import("../containers/UserPages/Wallet/FundReceivingHistory")
);
// Team section
const DirectTeam = React.lazy(() =>
  import("../containers/UserPages/MyTeamPage/Team.DirectTeamPage")
);
const LevelTeam = React.lazy(() =>
  import("../containers/UserPages/MyTeamPage/Team.LevelTeam")
);
const UserTeamStatistics = React.lazy(() =>
  import("../containers/UserPages/MyTeamPage/Team.Statistics")
);

const thisMonthBussiness = React.lazy(() =>
  import("../containers/UserPages/MyTeamPage/AllThisMonthTeamBussinessHistory")
);
// topUp section
const TopUpAccount = React.lazy(() =>
  import("../containers/UserPages/TopupPage/Topup.AccountTopupPage")
);
const TopUpHistory = React.lazy(() =>
  import("../containers/UserPages/TopupPage/Topup.TopupHistoryPage")
);
// earning section
const LevelIncome = React.lazy(() =>
  import("../containers/UserPages/Earnings/Earning.LevelIncome")
);
const DailyIncome = React.lazy(() =>
  import("../containers/UserPages/Earnings/Earning.DailyIncome")
);
const RankIncome = React.lazy(() =>
  import("../containers/UserPages/Earnings/Earning.RankIncome")
);
const BonusIncome = React.lazy(() =>
  import("../containers/UserPages/Earnings/Earning.BonusIncome")
);
const RoiIncome = React.lazy(() =>
  import("../containers/UserPages/Earnings/Earning.RoiIncome")
);
const Reward = React.lazy(() =>
  import("../containers/UserPages/Earnings/Earning.Reward")
);

// withdraw section
const WithdrawFund = React.lazy(() =>
  import("../containers/UserPages/WithdrawPage/Withdraw.WithdrawFundsPage")
);
const WithdrawHistory = React.lazy(() =>
  import("../containers/UserPages/WithdrawPage/Withdraw.ReportStatusPage")
);

// Support section
const updates = React.lazy(() =>
  import("../containers/UserPages/SupportPage/Support.UpdateNews")
);
const supportTicket = React.lazy(() =>
  import("../containers/UserPages/SupportPage/Support.SupportTicketPage")
);
const TicketHistory = React.lazy(() =>
  import("../containers/UserPages/SupportPage/Support.TicketHistoryPage")
);
const ContactUs = React.lazy(() =>
  import("../containers/UserPages/SupportPage/Support.ContactPage")
);
const UpgradeTopUpHistory = React.lazy(() =>
  import("../containers/UserPages/MyTeamPage/TeamLevelBuissinessHistory")
);
export const routers = [
  /****************************user routes *****************************************/
  {
    path: "*",
    exact: true,
    name: "Error",
    permission: ["user", "admin"],
    component: Page404,
  },
  // Dashboard
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    permission: ["user"],
    component: UserDashboard,
  },

  //###### SETTINGS CATEGORY#########################################
  // SETTINGS=>Profile Section
  {
    path: "/profile",
    name: "Welcome",
    permission: ["user"],
    component: MyProfile,
    exact: true,
  },
  {
    path: "/profile/my-profile",
    name: "My Profile",
    permission: ["user"],
    component: MyProfile,
  },
  {
    path: "/profile/edit-profile",
    name: "Update Profile",
    permission: ["user"],
    component: UpDateProfile,
  },
  {
    path: "/profile/update-email",
    name: "Update Email",
    permission: ["user"],
    component: UpDateEmail,
  },
  {
    path: "/profile/update-password",
    name: "Update Email",
    permission: ["user"],
    component: UpDatePassword,
  },
  // {
  //   path: "/profile/update-trxPassword",
  //   name: "Update Email",
  //   permission: ["user"],
  //   component: UpDateTrxPassword,
  // },
  {
    path: "/profile/edit-wallet",
    name: "Update Email",
    permission: ["user"],
    component: EditWallet,
  },
  {
    path: "/profile/add-pin",
    name: "add pin",
    permission: ["user"],
    component: addPin,
  },

  // wallet section
  {
    path: "/wallet/my-wallet",
    name: "Update Email",
    permission: ["user"],
    component: wallet,
  },
  {
    path: "/wallet/instant-deposit",
    name: "Update Email",
    permission: ["user"],
    component: InstantDeposit,
  },
  {
    path: "/wallet/deposit-fund",
    name: "Update Email",
    permission: ["user"],
    component: DepositFund,
  },
  {
    path: "/wallet/deposit-history",
    name: "Update Email",
    permission: ["user"],
    component: DepositHistory,
  },
  {
    path: "/wallet/fund-receiving-history",
    name: "Fund Receiving History",
    permission: ["user"],
    component: FundReceivingHistory,
  },

  // Team section
  {
    path: "/team",
    name: "direct team",
    permission: ["user"],
    component: DirectTeam,
  },
  {
    path: "/team/direct-team",
    name: "direct team",
    permission: ["user"],
    component: DirectTeam,
  },
  {
    path: "/team/level-team",
    name: "level team",
    permission: ["user"],
    component: LevelTeam,
  },
  {
    path: "/team/team-business",
    name: "Team Business",
    permission: ["user"],
    component: UserTeamStatistics,
  },
  {
    path: "/team/this_month_bussiness",
    name: "This Month Business",
    permission: ["user"],
    component: thisMonthBussiness,
  },
  {
    path: "/member/member-upgrade-top-up-business-history/:level/:userid",
    exact: true,
    name: "Top-Up History",
    permission: ["user"],
    component: UpgradeTopUpHistory,
  },
  // top-up
  {
    path: "/topup",
    name: "direct team",
    permission: ["user"],
    component: TopUpAccount,
  },
  {
    path: "/topup/topup-account",
    name: "direct team",
    permission: ["user"],
    component: TopUpAccount,
  },
  {
    path: "/topup/topup-history",
    name: "direct team",
    permission: ["user"],
    component: TopUpHistory,
  },
  // earning section
  {
    path: "/earnings/level-income",
    name: "Level Income",
    permission: ["user"],
    component: LevelIncome,
  },
  {
    path: "/earnings/daily-income",
    name: "Level Income",
    permission: ["user"],
    component: DailyIncome,
  },
  {
    path: "/earnings/roi-income",
    name: "Reward Income",
    permission: ["user"],
    component: RoiIncome,
  },
  {
    path: "/earnings/rank-income",
    name: "Rank Income",
    permission: ["user"],
    component: RankIncome,
  },
  {
    path: "/earnings/bonus-income",
    name: "Bonus Income",
    permission: ["user"],
    component: BonusIncome,
  },
  {
    path: "/earnings/reward",
    name: "Roi income",
    permission: ["user"],
    component: Reward,
  },

  // withdraw section
  {
    path: "/withdraw/withdraw-fund",
    name: "withdraw funds",
    permission: ["user"],
    component: WithdrawFund,
  },
  {
    path: "/withdraw/withdraw-history",
    name: "withdraw history",
    permission: ["user"],
    component: WithdrawHistory,
  },

  // Settings =>Support
  {
    path: "/support/updates",
    name: "updates",
    permission: ["user"],
    component: updates,
    exact: true,
  },
  {
    path: "/support/support-ticket",
    name: "support ticket",
    permission: ["user"],
    component: supportTicket,
    exact: true,
  },
  {
    path: "/support/ticket-history",
    name: "ticket history",
    permission: ["user"],
    component: TicketHistory,
    exact: true,
  },
  {
    path: "/support/contact-us",
    name: "Contact Us",
    permission: ["user"],
    component: ContactUs,
    exact: true,
  },
];

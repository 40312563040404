import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import logo from "../../../../assets/logo.png";
import CustomLink from "../../../../components/Link";
import {
  getLocalStorage,
  removeLocalStorage,
} from "../../../../utils/function/localStorage";
const Header = ({ hidePackageRoute }) => {
  const [stickyNav, setStickyNav] = useState(false);
  const stickyNavbar = () => {
    if (window.pageYOffset > 100) {
      setStickyNav(true);
      return;
    } else {
      setStickyNav(false);
      return;
    }
  };
  const handleLogout = () => {
    removeLocalStorage("safe_secure_token");
    window.location.reload();
  };
  window.addEventListener("scroll", stickyNavbar);
  const [showToggleNav, setShowToggleNav] = useState(false);
  return (
    <div
      className={`ss-trade_frontpage_header_wrapper ${
        stickyNav ? "sticky" : ""
      }`}
    >
      <div className="container">
        <div className="ss-trade_front_navbar">
          <div className="toggler_icon">
            <FaBars onClick={() => setShowToggleNav(!showToggleNav)} />
          </div>
          <div className="ss-trade_front_logo">
            <CustomLink href="/" className="logo">
              <img src={logo} alt="logo" />
              {/* <h2 style={{padding: "15px"}}>Safe & Secure Trade</h2> */}
            </CustomLink>
            <ul className="ss-trade_front_navbar_lists2">
              {getLocalStorage("safe_secure_token") ? (
                <li className="ss-trade_front_navbar_list2 ">
                  <CustomLink
                    href="/dashboard"
                    className="ss-trade_front_nav_link2 ss-trade_front_navbar_dashboard"
                  >
                    Dashboard
                  </CustomLink>
                </li>
              ) : (
                <li className="ss-trade_front_navbar_list2">
                  <CustomLink
                    href="/login"
                    className="ss-trade_front_nav_link2 login2"
                  >
                    Login
                  </CustomLink>
                </li>
              )}
              {getLocalStorage("safe_secure_token") ? (
                <li
                  className="ss-trade_front_navbar_list2"
                  onClick={handleLogout}
                >
                  <CustomLink
                    href="/login"
                    className="ss-trade_front_nav_link2 ss-trade_front_navbar_logOut"
                  >
                    Logout
                  </CustomLink>
                </li>
              ) : (
                <li className="ss-trade_front_navbar_list2">
                  <CustomLink
                    href="/register"
                    className="ss-trade_front_nav_link2 register2"
                  >
                    Register
                  </CustomLink>
                </li>
              )}
            </ul>
          </div>
          <div
            className={`ss-trade_front_navbar_menu ${
              showToggleNav ? "toggle_navbar" : ""
            }`}
          >
            <ul className="ss-trade_front_navbar_lists">
              <li className="ss-trade_front_navbar_list">
                <CustomLink href="/" className="ss-trade_front_nav_link">
                  Home
                </CustomLink>
              </li>

              <li className="ss-trade_front_navbar_list">
                <CustomLink href="/planPDF" className="ss-trade_front_nav_link">
                  Plan PDF
                </CustomLink>
              </li>
              {/* <li className="ss-trade_front_navbar_list">
                <CustomLink
                  href="https://play.google.com/store/apps/details?id=com.safeandsecuretrade"
                  className="ss-trade_front_nav_link"
                >
                  Download App
                </CustomLink>
              </li> */}
              {/* <li className="ss-trade_front_navbar_list">
                <CustomLink href="/gallary" className="ss-trade_front_nav_link">
                  Gallery
                </CustomLink>
              </li> */}
              {/* <li className="ss-trade_front_navbar_list">
                <CustomLink href="/contact" className="ss-trade_front_nav_link">
                  Contact US
                </CustomLink>
              </li>
              <li className="ss-trade_front_navbar_list">
                <CustomLink href="/income-types" className="ss-trade_front_nav_link">
                  Income Types
                </CustomLink>
              </li> */}
              <li class="ss-trade_front_navbar_list">
                <a class="ss-trade_front_nav_link" href="/">
                  Coming soon
                </a>
                <div class="sub-menu-l">
                  <ul>
                    <li>
                      <a class="undefined inactive" href="/coming-soon/broker">
                        Broker
                      </a>
                    </li>
                    <li>
                      <a
                        class="undefined inactive"
                        href="/coming-soon/real-estate"
                      >
                        Real Estate
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              {getLocalStorage("safe_secure_token") ? (
                <li className="ss-trade_front_navbar_list register">
                  <CustomLink
                    href="/dashboard"
                    className="ss-trade_front_nav_link"
                  >
                    Dashboard
                  </CustomLink>
                </li>
              ) : (
                <li className="ss-trade_front_navbar_list login">
                  <CustomLink href="/login" className="ss-trade_front_nav_link">
                    Login
                  </CustomLink>
                </li>
              )}
              {getLocalStorage("safe_secure_token") ? (
                <li
                  className="ss-trade_front_navbar_list login"
                  onClick={handleLogout}
                >
                  <CustomLink href="/login" className="ss-trade_front_nav_link">
                    Logout
                  </CustomLink>
                </li>
              ) : (
                <li className="ss-trade_front_navbar_list register">
                  <CustomLink
                    href="/register"
                    className="ss-trade_front_nav_link"
                  >
                    Register
                  </CustomLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
